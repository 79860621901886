import React from 'react';
import Orders from './order';
import logo from './logo.jpg'; // Replace with the actual path to your logo


const App = () => {
  return (
    <div className="App">
     
      <h1>Admin Orders Dashboard</h1>
     
      {/* Render Orders Component */}
      <Orders />
      <footer style={{ textAlign: 'center', marginTop: '20px' }}>
        <img src={logo} alt="HooShop Logo" style={{ width: '150px', marginBottom: '10px' }} />
        <p>Powered by HooShop</p>
        <p>Looking to build a custom app for your restaurant? Contact us today!</p>
        <p>Email: <a href="mailto:hooshopservice@gmail.com">hooshopservice@gmail.com</a> | Phone: +91 9817409607</p>
      </footer>
    </div>
  );
};

export default App;
