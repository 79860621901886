import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './order.css';
import QRCode from 'qrcode';
// import logo from './logo.png';
// import thaliImage from './specialThaliImage.jpg';
import AccessCode from './AccessCode';  // Import the AccessCode component
import logom from './logom.png';


// const logoBase64 = logo;  
// const thaliImageBase64 = thaliImage;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [hasAccess, setHasAccess] = useState(false); // State for access control
   // Fetch orders when the user has access
   useEffect(() => {
    if (hasAccess) {
      const fetchOrders = async () => {
        try {
          const response = await axios.get('https://ctserver.vercel.app/api/orders');
          if (Array.isArray(response.data)) {
            const now = new Date();
            const filtered = response.data.filter(order => {
              const orderDate = new Date(`${order.date} ${order.time}`);
              const timeDifference = (now - orderDate) / (1000 * 60 * 60);
              return timeDifference <= 48;
            });

            const sortedOrders = filtered.sort((a, b) => {
              const dateA = new Date(`${a.date} ${a.time}`);
              const dateB = new Date(`${b.date} ${b.time}`);
              return dateB - dateA;
            });

            setOrders(sortedOrders);
            setFilteredOrders(sortedOrders);
          } else {
            setError('Unexpected response format');
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchOrders();
    }
  }, [hasAccess]);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filtered = orders.filter((order) => {
      const orderDate = new Date(order.date).toLocaleDateString();
      return (
        (order.orderNumber || '').includes(searchValue) ||
        (order.customerName || '').toLowerCase().includes(searchValue) ||
        (order.name || '').toLowerCase().includes(searchValue) ||
        (order.contactNumber || '').toLowerCase().includes(searchValue) ||
        (order.paymentMethod || '').toLowerCase().includes(searchValue) ||
        orderDate.includes(searchValue)
      );
    });

    setFilteredOrders(filtered);
  };

  // Handle access granted when the correct code is entered
  const handleAccessGranted = () => {
    setHasAccess(true);
  };

  if (!hasAccess) {
    // Render the AccessCode component if access is not granted
    return <AccessCode onAccessGranted={handleAccessGranted} />;
  }

  if (loading) return <div className="loading">Loading orders, please wait...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  const OrderComponent = ({ orders }) => {

    // Function to send all orders to WhatsApp
    const sendAllOrdersToWhatsApp = () => {
  
      // Ensure orders is an array
      if (!Array.isArray(orders)) {
        console.error("Orders is not an array");
        return;
      }
  
      const allOrderDetails = orders.map(order => `
        
  
          
          *Bill No:* ${order.orderNumber}\n
         
          *Order Details:*\n
          ${Array.isArray(order.order)
              ? order.order.map(item => `- ${item.itemName} x${item.quantity} : ₹${item.price}`).join('\n')
              : order.order
          }\n\n
  
          ---------------------\n
      `).join('');
  
      const message = `
        *Orders Details:*\n
        ${allOrderDetails}
        Thank you for ordering with Clickthali!
      `;
  
      const whatsappApiUrl = `https://api.whatsapp.com/send?phone=918168585727&text=${encodeURIComponent(message)}`;
  
      // Open WhatsApp to send the message
      window.open(whatsappApiUrl, '_blank');
    };
    return (
      <div>
       
        <ul>
          {Array.isArray(orders) && orders.length > 0 ? (
            orders.map(order => (
              <li key={order.orderNumber}>
                <strong>Order No:</strong> {order.orderNumber} | <strong>Name:</strong> {order.name}
              </li>
            ))
          ) : (
            <p>No orders available</p>
          )}
        </ul>
  
        <button onClick={sendAllOrdersToWhatsApp}>
          Send All Orders to WhatsApp
        </button>
      </div>
    );
  };
  

 
  const handlePrint = async (order) => {
    const upiId = "8168197978@ptyes";
    const upiNote = encodeURIComponent("Payment for Clickthali Food");
    const upiLink = `upi://pay?pa=${upiId}&pn=PaperPalace&am=${order.grandTotal}&tn=${upiNote}${order.orderNumber}`;
    
    const qrCodeUrl = await QRCode.toDataURL(upiLink, { width: 200 });
    const win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head><title>Invoice</title>');
  
    win.document.write(`
      <style>
        @media print { .no-print { display: none; } }
        body { font-family: Arial, sans-serif; margin: 20px; }
        .header { text-align: center; }
        .header img { max-height: 100px; }
        .bill-details { margin-top: 20px; }
        table { width: 100%; border-collapse: collapse; margin-top: 20px; }
        th, td { border: 1px solid black; padding: 8px; text-align: left; }
        .qr-code { margin-top: 20px; text-align: center; z-index: 1;}
        .footer { position: relative; bottom: 150px; left: 20px; }
        .footer img { width: 150px; transform: rotate(-20deg); opacity: 1; z-index: -111;}
      </style>
    `);
  
    win.document.write('</head><body>');
  
    win.document.write(`
      <div class="header">
        
        <h2>CLICKTHALI RESTAURANTS</h2>
        <p>Address: Rohtak, Haryana - 124001</p>
        <p>Contact:+91 81685 85727</p>
        <h3><strong>TAX INVOICE</strong></h3>
      </div>
    `);
  
    win.document.write(`
      <div class="bill-details">
        <p><strong>Bill No:</strong> ${order.orderNumber}</p>
        <p><strong>Date & Time:</strong> ${order.date} ${order.time}</p>
        <p><strong>Name:</strong> ${order.name}</p>
        <p><strong>Address:</strong> ${order.table}</p>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Price (₹)</th>
            </tr>
          </thead>
          <tbody>
            ${
              Array.isArray(order.order)
                ? order.order.map(item => `
                    <tr>
                      <td>${item.itemName}</td>
                      <td>${item.quantity}</td>
                      <td>${item.price}</td>
                    </tr>
                  `).join('')
                : `<tr><td colspan="3"><p>${order.order}</p></td></tr>`
            }
            <tr>
              <td colspan="2"><strong>Total</strong></td>
              <td><strong>₹${order.total}</strong></td>
            </tr>
            <tr>
              <td colspan="2"><strong>Delivery Cost</strong></td>
              <td><strong>₹${order.deliveryCost}</strong></td>
            </tr>
            <tr>
              <td colspan="2"><strong>Grand Total</strong></td>
              <td><strong>₹${order.grandTotal}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    `);
  
    win.document.write(`
      <div class="qr-code">
        <h3>Scan the QR Code to Pay via UPI</h3>
        <img src="${qrCodeUrl}" alt="QR Code" />
      </div>
    `);
  
    // win.document.write(`
    //   <div class="footer">
    //     <img src="${thaliImageBase64}" alt="Thali Image" />
    //   </div>
    // `);
  
    win.document.write('</body></html>');
    win.document.close();
    win.focus();
    win.print();
  };

  const handleFieldChange = async (orderId, field, value) => {
    try {
      let parsedValue = value;
      if (['total', 'grandTotal', 'deliveryCost'].includes(field)) {
        parsedValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      }
      const updatedOrders = orders.map((order) =>
        order._id === orderId ? { ...order, [field]: parsedValue } : order
      );
      setOrders(updatedOrders);
      setFilteredOrders(updatedOrders);
      await axios.put(`https://ctserver.vercel.app/api/orders/${orderId}`, { [field]: parsedValue });
    } catch (err) {
      console.error('Failed to update order field:', err);
      setError('Failed to update order field');
    }
  };

  // const handleDelete = async (orderId) => {
  //   if (window.confirm('Are you sure you want to delete this order?')) {
  //     try {
  //       await axios.delete(`https://ctserver.vercel.app/api/orders/${orderId}`);
  //       setOrders(orders.filter(order => order._id !== orderId));
  //       setFilteredOrders(filteredOrders.filter(order => order._id !== orderId));
  //     } catch (err) {
  //       setError('Failed to delete order');
  //     }
  //   }
  // };

  if (loading) return <div className="loading">Loading orders, please wait...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="orders-container">
      
    <h1>All Orders</h1>
    <img src={logom} alt="Logo" style={{ alignContent: 'center', width: '100px', margint: '10px' }}/><input
    
      type="text"
      placeholder="Search by Order Number, Name, Payment Method, Date, or Contact Number"
      value={searchTerm}
      onChange={handleSearch}
      className="search-bar"
    />
    {filteredOrders.length > 0 ? (
      <table className="order-table">
        <thead>
          <tr>
            <th>Serial No.</th>
            <th>Order Number</th>
            <th>Date & Time</th>
            <th>Name</th>
            <th>Address</th>
            <th>Contact Number</th>
            <th>Order Details</th>
            <th>Total (₹)</th>
            <th>Delivery Cost (₹)</th>
            <th>Grand Total (₹)</th>
            <th>Payment Method</th>
            <th>Status</th>
            <th className="no-print">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order, index) => (
            <tr key={order._id}>
              <td>{index + 1}</td>
              <td>{order.orderNumber}</td>
              <td>{`${order.date} ${order.time}`}</td>
              <td>{order.name}</td>
              <td>{order.table}</td>
              <td>
              <a 
  href={`https://api.whatsapp.com/send?phone=+91${order.contactNumber}&text=${encodeURIComponent(`
    🍽️ *Yum! Your Order is Confirmed!* 🍽️\n
    Hey ${order.name}, thanks for ordering from *Clickthali*! Your order #${order.orderNumber} is being prepared fresh just for you! 😋\n

    📝 *Order Details:*\n
    ${Array.isArray(order.order)
      ? order.order.map(item => `• ${item.itemName} x${item.quantity} - ₹${item.price}`).join('\n')
      : order.order
    }\n
    🚚 *Delivery Fee:* ₹${order.deliveryCost}\n
    💵 *Total Amount:* ₹${order.grandTotal}\n

    🔥 *Cooking Up Something Special!* 🔥\n
    Your meal is being crafted with love and care. We’ll be at your doorstep soon with a hot and delicious delivery! 🚴💨\n
    
    ⭐ *Enjoy and Share Your Experience!* ⭐\n
    We’d love to hear how we did! Share your thoughts here: https://g.page/r/CQ5ZZuaiVFkQEAI/review \n

    Thank you for choosing *Clickthali*! 🙌 We hope you enjoy every bite! 😋🍴
  `)}`} 
  target="_blank" 
  rel="noopener noreferrer"
>
  Contact {order.contactNumber}
</a>


</td>

              <td>
                {Array.isArray(order.order) ? (
                  <ul>
                    {order.order.map((item, i) => (
                      <li key={i}>{item.itemName} x {item.quantity}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{order.order}</p>
                )}
              </td>
              <td>₹{order.total}</td>
              <td>₹{order.deliveryCost}</td>
              <td>₹{order.grandTotal}</td>
              <td>
                  <select
                    value={order.paymentMethod}
                    onChange={(e) => handleFieldChange(order._id, 'paymentMethod', e.target.value)}
                  >
                    <option value="Cash">Cash</option>
                    <option value="UPI">UPI</option>
                  </select>
                </td>
              <td>
                <select
                  value={order.status}
                  onChange={(e) => handleFieldChange(order._id, 'status', e.target.value)}
                >
                  <option value="Running">Running</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Not Acceptable">Not Acceptable</option>
                </select>
              </td>
              <td className="no-print">
                <button onClick={() => handlePrint(order)}>Print Invoice</button>
                {/* <button onClick={() => handleDelete(order._id)}>Delete</button> */}
              </td>
            </tr>
          ))}
        </tbody>
       
      </table>
    ) : (
      <p>No orders found.</p>
    )}
     <div>
      <h1>Welcome to ClickThali</h1>
      <h2>All Orders</h2>
      <OrderComponent orders={orders} />
      <h2>Running Orders</h2>
      <OrderComponent orders={orders.filter(order => order.status === 'Running')} />

    </div>

  </div>
 
);
};

export default Orders;
