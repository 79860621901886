// src/components/AccessCode.js
import React, { useState } from 'react';

const AccessCode = ({ onAccessGranted }) => {
  const [codeInput, setCodeInput] = useState('');
  const correctCode = 'BVM81681'; // Set your access code here

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    if (codeInput === correctCode) {
      onAccessGranted(true); // Call the callback to grant access
    } else {
      alert('Incorrect code. Please try again.');
    }
  };

  return (
    <div className="code-access-container">
      <h1>Enter Access Code</h1>
      <form onSubmit={handleCodeSubmit}>
        <input
          type="text"
          value={codeInput}
          onChange={(e) => setCodeInput(e.target.value)}
          placeholder="Enter code"
          className="code-input"
        />
        <button type="submit" className="code-submit-button">Submit</button>
      </form>
    </div>
  );
};

export default AccessCode;
